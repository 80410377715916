<template>
  <div style="opacity:0;">
    <img class="logo1-icon" src="@/assets/logo1.png" />
    <img class="logo2-icon" src="@/assets/logo2.png" />
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      
    }
  },
  computed:{
    
  },
  components: {},
  created(){
    
  },
  mounted(){
    
  },
  methods:{
    
  }
}
</script>

<style scoped>
  .logo1-icon,.logo2-icon{
    position: fixed;
  }
  .logo1-icon{
    top:0.26rem;
    left: 0.37rem;
    width: 1.77rem;
    height: 0.68rem;
  }
  .logo2-icon{
    top:0.24rem;
    right: 0.24rem;
    width: 2.79rem;
    height: 0.46rem;
  }
</style>
